<style lang="scss">
.base-btn {
  border: none;
  outline: none;
  border-radius: 0.3rem;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-family: "cheap-pine-sans", sans-serif;
  font-size: 1.25rem;
  display: block;
  text-decoration: none;
  line-height: inherit;
  text-align: center;
  cursor: pointer;
  transition: 350ms;
  &:hover,
  &:active,
  &.router-link-active {
    color: white !important;
    //box-shadow: 0 0 8px white;
  }
}

.base-btn.primary {
  background: linear-gradient($primary, $primary-dark);
  color: $secondary;
}

.base-btn.btn-block {
  @media screen and (min-device-width: 700px) {
    display: inline-block;
    width: auto;
  }
}
</style>

<template>
  <template v-if="to">
    <router-link :to="to" class="base-btn btn-block primary">
      <slot></slot>
    </router-link>
  </template>
  <template v-else>
    <button class="base-btn btn-block primary">
      <slot></slot>
    </button>
  </template>
</template>

<script>
export default {
  name: "BaseBtn",
  props: {
    to: {
      type: String,
      default: ""
    }
  }
};
</script>
