<style lang="scss" scoped>
.btn-burger {
  background: none;
  border: none;
  outline: none;
  color: inherit;
}
</style>

<template>
  <button class="btn-burger" @click="$store.commit('nav/OPEN_NAV')">
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="6" width="28" height="4" rx="2" fill="currentColor" />
      <rect x="2" y="14" width="28" height="4" rx="2" fill="currentColor" />
      <rect x="2" y="22" width="28" height="4" rx="2" fill="currentColor" />
    </svg>
  </button>
</template>

<script>
export default {
  name: "BtnBurger"
};
</script>
